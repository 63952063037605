import React from 'react'
import { Helmet } from 'react-helmet'
import { Header, List, Container, Segment, Responsive } from 'semantic-ui-react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import { withTranslation } from 'react-i18next'

import Layout from '../components/Layouts/PageLayout'
import Comments from '../components/Comments/Comments'
import { unescape } from '../utils/helpers'
import { computerBreakpoint } from '../utils/constants'

// Blog detail template
const BlogPage = ({ data, t, pageContext }) => {
  const {
    featured_media: featuredMedia,
    title,
    excerpt,
    date,
    author = {},
    content,
    wordpress_id: wordpressId,
    categories = [],
    acf,
    yoast
  } = data.wordpressPost

  const isIE = false || (process.browser && document && !!document.documentMode)

  return (
    <div>
      <Helmet>
        <title>{title + t('htmlTitleSuffix')}</title>

        <meta name='description' content={yoast ? yoast.metadesc : ''} />
        <meta
          name='image'
          content={
            featuredMedia &&
            featuredMedia.localFile &&
            featuredMedia.localFile.childImageSharp.fluid.src
          }
        />

        <meta property='og:title' content={title} />
        <meta property='og:description' content={yoast ? yoast.metadesc : ''} />
        <meta
          property='og:image'
          content={
            featuredMedia &&
            featuredMedia.localFile &&
            featuredMedia.localFile.childImageSharp.fluid.src
          }
        />

        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:creator' content='tourasia' />
        <meta name='twitter:title' content={title} />
        <meta
          name='twitter:description'
          content={yoast ? yoast.metadesc : ''}
        />
        <meta
          name='twitter:image'
          content={
            featuredMedia &&
            featuredMedia.localFile &&
            featuredMedia.localFile.childImageSharp.fluid.src
          }
        />
      </Helmet>

      <Layout pageContext={pageContext}>
        {isIE ? (
          <div className='ie-image'>
            {featuredMedia && featuredMedia.localFile && (
              <Img
                fluid={{
                  ...featuredMedia.localFile.childImageSharp.fluid,
                  aspectRatio: 2.5
                }}
                alt={featuredMedia.alt_text || featuredMedia.title}
              />
            )}
          </div>
        ) : (
          featuredMedia &&
          featuredMedia.localFile && (
            <Img
              fluid={{
                ...featuredMedia.localFile.childImageSharp.fluid,
                aspectRatio: 2.5
              }}
              alt={featuredMedia.alt_text || featuredMedia.title}
            />
          )
        )}
        <Segment
          basic
          vertical
          color='red'
          inverted
          textAlign='center'
          padded='very'
        >
          <Container>
            <Responsive maxWidth={computerBreakpoint - 1}>
              <Header as='h1' inverted size='large'>
                {unescape(title)}
              </Header>
            </Responsive>
            <Responsive minWidth={computerBreakpoint}>
              <Header as='h1' inverted>
                {unescape(title)}
              </Header>
            </Responsive>
            <Header as='h3' inverted>
              <div
                dangerouslySetInnerHTML={{
                  __html: yoast ? yoast.metadesc : excerpt
                }}
              />
            </Header>
            <List horizontal divided inverted>
              <List.Item>{date}</List.Item>
              {!!categories.length && (
                <List.Item>
                  {t('blog-page.category')}:{' '}
                  {categories.map(({ name }) => `${name} `)}
                </List.Item>
              )}
              {author.name && (
                <List.Item>
                  {t('blog-page.author')}:{' '}
                  {unescape((acf && acf.blog_author) || author.name)}
                </List.Item>
              )}
            </List>
          </Container>
        </Segment>

        <Segment basic vertical padded='very'>
          <Container text>
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              className='wp-blog'
            />
          </Container>
          <Container text>
            <Comments postId={wordpressId} />
          </Container>
        </Segment>
      </Layout>
    </div>
  )
}

export default withTranslation()(BlogPage)

export const pageQuery = graphql`
  query BlogPageQuery($slug: String!) {
    wordpressPost(slug: { eq: $slug }) {
      wordpress_id
      date(formatString: "DD.MM.YYYY")
      slug
      title
      content
      excerpt
      featured_media {
        title
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, cropFocus: ATTENTION) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      author {
        name
      }
      categories {
        name
      }
      acf {
        blog_author
      }
      yoast {
        metadesc
      }
    }
  }
`
