import React from 'react'
import axios from 'axios'
import { Segment } from 'semantic-ui-react'

import Comment from './Comment'
import CommentForm from './CommentForm'

class Comments extends React.Component {
  state = {
    comments: []
  }

  componentDidMount () {
    this.refresh()
  }

  refresh = () => {
    axios.get(`${process.env.WP_API_ENDPOINT}/comments`)
      .then(({ data: allComments }) => {
        const comments = allComments.filter(comment => comment.post === this.props.postId)
        this.setState({ comments })
      })
  }

  render () {
    const { postId } = this.props

    return (
      <Segment padded='very' basic vertical>
        <CommentForm postId={postId} />
        {this.state.comments &&
          this.state.comments.map(({ id, author_name: authorName, date, content, parent }) => (
            !parent &&
            <Comment
              key={id}
              author={authorName}
              dateTime={date}
              content={content.rendered}
              commentParentId={parent}
              id={id}
              postId={postId}>
              {this.state.comments
                .filter(({ parent }) => parent === id)
                .map(({ id, author_name: authorName, date, content, parent }) => (
                  <Comment
                    key={id}
                    className='subcomment'
                    author={authorName}
                    dateTime={date}
                    content={content.rendered}
                    commentParentId={parent}
                    postId={postId}
                  />
                ))
              }
            </Comment>
          ))}
      </Segment>
    )
  }
}

export default Comments
