import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

import {
  Segment,
  TextArea,
  Form,
  Button,
  Header,
  Grid
} from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

import Share from '../Common/Share'

import { modalActions } from '../Modals/state'

class CommentForm extends React.Component {
  state = {
    author_name: '',
    author_email: '',
    date: '',
    content: '',
    loading: false
  }

  handleInputChange = event => {
    const name = event.target.name
    const value = event.target.value

    this.setState({
      ...this.state,
      [name]: value
    })
  }

  submit = (event) => {
    const { postId, commentParentId, afterSubmit, toggleModal, t } = this.props
    const postUrl = `${process.env.WP_API_ENDPOINT}/comments`

    const form = event.target

    this.setState({ loading: true })

    axios
      .post(
        postUrl,
        { ...this.state, date: new Date(), post: postId, parent: commentParentId || 0 }
      )
      .then(() => {
        this.setState({ loading: false })
        toggleModal('notification', { type: 'success', text: t('comment-form.thanks') })
        form.reset()
      })
      .catch((error) => {
        console.log(error)
        this.setState({ loading: false })
        toggleModal('notification', { type: 'error', text: t('comment-form.wrong') })
      })

    afterSubmit && afterSubmit()

    return true
  }

  render () {
    const { loading } = this.state
    const { t } = this.props
    return (
      <Segment basic vertical>
        <Form onSubmit={this.submit}>
          {!this.props.commentParentId ? (
            <Grid>
              <Grid.Column width={12}>
                <Header as='h2'>{t('comment-form.header')}</Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign='right'>
                <Share color='grey' />
              </Grid.Column>
            </Grid>
          ) : (
            <Header as='h3'>{t('comment-form.answer')}</Header>
          )}

          <Grid columns='equal'>
            <Grid.Column>
              <TextArea
                placeholder={t('comment-form.form.comment')}
                name='content'
                onChange={this.handleInputChange}
                rows='7'
                required
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                placeholder={t('comment-form.form.name')}
                name='author_name'
                onChange={this.handleInputChange}
                fluid
                required
              />
              <Form.Input
                placeholder={t('comment-form.form.email')}
                type='email'
                name='author_email'
                onChange={this.handleInputChange}
                fluid
                required
              />

              <Button content={t('comment-form.form.send')} floated='right' loading={loading} color='red' />
            </Grid.Column>
          </Grid>
        </Form>
      </Segment>
    )
  }
}

const mapDispatchToProps = {
  toggleModal: modalActions.toggleModal
}

export default withTranslation()(connect(null, mapDispatchToProps)(CommentForm))
