import React from 'react'
import { Segment, Grid, Header, Divider, Icon } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

import CommentForm from './CommentForm'

class Comment extends React.Component {
  state = {
    showForm: false
  }

  toggleShowForm = () => {
    this.setState({ showForm: !this.state.showForm })
  }

  render () {
    const { t, id, dateTime, content, author, postId, commentParentId, children } = this.props
    return (
      <Segment className='lightgrey' inverted padded='very'>
        <Grid columns='equal'>
          <Grid.Column>
            <Header as='h3' color='red'>{author}</Header>
          </Grid.Column>
          <Grid.Column textAlign='right'>
            <Header as='h5' color='grey'>
              <Icon className='ta-time' color='red' size='large' fitted /> {dateTime.slice(0, -9)}
            </Header>
          </Grid.Column>
        </Grid>
        <Segment basic vertical>
          <p dangerouslySetInnerHTML={{ __html: content }} />
        </Segment>
        <Divider />
        {!commentParentId &&
          <div className='divider-button'>
            <span onClick={this.toggleShowForm} style={{ cursor: 'pointer' }}><Icon className='ta-comment' /> {t('comment.respond')}</span>
          </div>
        }
        <div style={this.state.showForm ? { display: 'block' } : { display: 'none' }}>
          <CommentForm postId={postId} commentParentId={id} afterSubmit={this.toggleShowForm} />
          <Divider className='red-divider' />
        </div>
        {children}
      </Segment >
    )
  }
}
export default withTranslation()(Comment)
